import React from 'react';
import './article2.css';
import "../../components/navbar/navbar.css"

const Article2 = ({ imgUrl }) => (
  <div className="gpt3__cert-container_article">
    <div className="gpt3__cert-container_article-image">
      <img src={imgUrl} alt="cert_image" />
    </div>
  </div>
);

export default Article2;