import React from 'react';
import Article2 from '../../components/article2/Article2';
import { blog01, blog02, blog03, blog04, blog05 } from './imports';

import { cert1, cert2, cert3, cert4, cert5, cert6
} from "./imports"
import './cert.css';

const Cert = () => (
  <div className="gpt3__cert section__padding" id="cert">
    <div className="gpt3__cert-heading">
      <h1 className="gradient__text">Certificari</h1>
    </div>
    <div className="gpt3__cert-container">
      <div className="gpt3__cert-container_groupB">
        <Article2 imgUrl={cert1} />    
        <Article2 imgUrl={cert3} />
        <Article2 imgUrl={cert2} />
        <Article2 imgUrl={cert4} />
        <Article2 imgUrl={cert5} />
        <Article2 imgUrl={cert6} />
      </div>
      <div className="gpt3__cert-container_groupB">
        
      </div>
    </div>
  </div>
);

export default Cert;
