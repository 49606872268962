import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

function Read() {
	const [data, setData] = useState([]);
	const { id } = useParams();
	useEffect(() => {
		axios
		.get(`http://localhost:1000/get_awb/${id}`)
		.then((res) => {
			console.log(res.data)
			setData(res.data);
		})
		.catch((err) => console.log(err));
	}, [id]);

	const months = ["ianuarie", "februarie", "martie", "aprilie", "mai", "iunie", "iulie", "august", "septembrie", "octombrie", "noiembrie", "decembrie"]

	return (
		<div className="container vw-100 vh-100">
			<h1 className="my-3 ms-4">Information - {id}</h1>
			<Link to="/admin" className="btn btn-success my-3 ms-4">Back</Link>
			<div className="my-3 ms-4">
				{
					data.length != 0 ? data.map((del)=>{
						return (<ul key={Math.random()} className="list-group">
							<li className="list-group-item">
								<b>AWB: </b>
								{del.AWB}
							</li>
							<li className="list-group-item">
								<b>Route: </b>
								{del.Route}
							</li>
							<li className="list-group-item">
								<b>PkgDetails: </b>
								{del.PkgDetails}
							</li>
							<li className="list-group-item">
								<b>Status: </b>
								<table className="table">
									<thead>
										<tr>
											<th>Date</th>
											<th>Status</th>
											<th>Details</th>
										</tr>
									</thead>
									<tbody>
									{
										JSON.parse(del.Status).map((change) => {
											return (
												<tr key={Math.random()}>
													<td>{new Date(change.date).getDate() + "-" + months[new Date(change.date).getMonth()] + "-" + new Date(change.date).getFullYear() + " | " + new Date(change.date).getHours() + ":" + ('0' + new Date(change.date).getMinutes()).slice(-2)}</td>
													<td>{change.details}</td>
													<td>{change.comments}</td>
												</tr>
											)
										})
									}
									</tbody>
								</table>
							</li>
						</ul>)
					}) : <p>AWB does not exist or failed to be fetched.</p>
				} 
			</div>
		</div>
	);
}

export default Read;