import React from 'react';
import possibilityImage from '../../assets/possibility.png';
import './possibility.css';

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="about">
    <div className="gpt3__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="gpt3__possibility-content">
      <h1 className="gradient__text">Despre noi</h1>
      <p>Companie romaneasca, fondata si administrata ca afacere de familie, cu valori solide, credem cu tarie ca dedicarea noastra personala si calitatea inalta a serviciilor fac diferenta.</p>
      <p>Punem in munca noastra pasiunea pe care o impartasim, fiind permanent in miscare, adaptandu-ne cerintelor individuale ale clientilor nostri si ale domeniului in care activam.</p>
      <p>Folosim experienta noastra pentru a crea o baza solida pt evolutia sustenabila a generatiilor viitoare.</p>
    </div>
  </div>
);

export default Possibility;
