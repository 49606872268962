// import React from 'react';

// import { Footer, Blog, Possibility, Features, WhatGPT3, Header, Cert } from './containers';
// import { CTA, Brand, Navbar } from './components';

// import './App.css';

// import 

// const App = () => (
//   <div className="App">
//     <div className="gradient__bg">
//       <Navbar />
//       <Header />
//     </div>
//     <Blog />  
//     <Features />
//     <Possibility />
//     <CTA />
//     <Cert />
//     <Footer />
//   </div>
// );

// export default App;

import React from 'react'
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";

import Home from "./elements/Home";
import Create from "./elements/Create";
import Edit from "./elements/Edit";
import Read from "./elements/Read";
import AdminLogin from './elements/AdminLogin';
import Main from './Main';

const isTokenExpired = token => Date.now() >= (JSON.parse(atob(token.split('.')[1]))).exp * 1000

const App = () => {
	const user = localStorage.getItem("token");

	const shouldDisplay = user ? !isTokenExpired(user) : false

  	return (
    	<BrowserRouter>
			<Routes>
				{shouldDisplay && <Route path="/admin" element={<Home />} />}
				{shouldDisplay && <Route path="/admin/create" element={<Create />} />}
				{shouldDisplay && <Route path="/admin/edit/:id" element={<Edit />} />}
				{shouldDisplay && <Route path="/admin/read/:id" element={<Read />} />}
				
				{/* <Route path="/create" element={<Create />} />
				<Route path="/edit/:id" element={<Edit />} />
				<Route path="/read/:id" element={<Read />} /> */}
				{shouldDisplay ? <Route path="/admin/login" element={<Navigate replace to="/admin" />} /> : <Route path="/admin/login" element={<AdminLogin />} />}
				<Route path="/admin" element={<Navigate replace to="/admin/login" />} />
        <Route path="/admin/:id" element={<Navigate replace to="/admin/login" />} />
        <Route path="/:id" element={<Main />} />
        <Route path="/" element={<Main />} />
			</Routes>
		</BrowserRouter>
  	)
}

export default App