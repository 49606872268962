import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function Home() {
    const [data, setData] = useState([])
    const [deleted, setDeleted] = useState(true)
    const [search, setSearch] = useState('')

    const handleLogout = () => {
		localStorage.removeItem("token");
		window.location.reload();
	};

    const months = ["ianuarie", "februarie", "martie", "aprilie", "mai", "iunie", "iulie", "august", "septembrie", "octombrie", "noiembrie", "decembrie"]

    useEffect(()=>{
        if(deleted) {
            setDeleted(false)
            axios.get('http://localhost:1000/awbs')
            .then((res)=>{
                var temp = res.data;
                setData(temp)
            })
            .catch((err)=>console.log(err))
        }
    }, [deleted])

    function handleDelete(id){
        axios.delete(`http://localhost:1000/delete/${id}`)
        .then((res)=>{
            setDeleted(true)
        })
        .catch((err)=> console.log(err))
    }

    return (
        <div className='container vh-90 vw-90'>
            <h3 className="my-3 ms-4">Admin Page</h3>
            <div className='d-flex justify-content-between my-3'>
                <div>
                    <Link className='btn btn-success mx-4' to='/admin/create'>Add AWB</Link>
                    <button onClick={handleLogout} className='btn btn-outline-danger'>Log Out</button>
                </div>
                <form>
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Search" onChange={(e) => setSearch(e.target.value)}/>
                        <div className="input-group-btn mx-2">
                            <Link to={`/admin/read/${search}`} className="btn btn-primary" type="submit">
                                Search
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
            <table className='table ms-4'>
                <thead>
                    <tr>
                        <th>AWB   </th>
                        <th>Route   </th>
                        <th>PkgDetails   </th>
                        <th>Status   </th>
                        <th>Actions   </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((del)=>{
                            return (<tr key={Math.random()}>
                                <td>{del.AWB}   </td>
                                <td>{del.Route}   </td>
                                <td>{del.PkgDetails}   </td>
                                <td>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>Date   </th>
                                                <th>Status   </th>
                                                <th>Comments   </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            JSON.parse(del.Status).map((change) => {
                                                return (
                                                    <tr key={Math.random()}>
                                                        <td>{new Date(change.date).getDate() + " " + months[new Date(change.date).getMonth()] + " " + new Date(change.date).getFullYear() + " | " + new Date(change.date).getHours() + ":" + ('0' + new Date(change.date).getMinutes()).slice(-2)}   </td>
                                                        <td>{change.details}   </td>
                                                        <td>{change.comments}   </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    <Link className='btn mx-2 btn-success' to={`/admin/read/${del.AWB}`}>Read</Link>
                                    <Link className='btn mx-2 btn-success' to={`/admin/edit/${del.AWB}`}>Edit</Link>
                                    <button onClick={()=>handleDelete(del.AWB)} className='btn mx-2 btn-danger'>Delete</button>
                                </td>
                            </tr>)
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default Home