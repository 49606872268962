import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Ambalare si monitorizare',
    text: 'Cu suportul unei rețele globale de parteneri specializați, oferim ambalaje pentru transport la temperaturi controlate și comenzi de gheață carbonică în majoritatea țărilor lumii. Colaborăm cu producători de top pentru a alege cele mai potrivite soluții de ambalare pentru fiecare expediere în parte și furnizăm echipamente de monitorizare a temperaturii, asigurându-ne că articolele dvs. sunt livrate în condiții optime.',
  },
  {
    title: 'Consultanta',
    text: 'Comunicăm eficient cu reprezentanții implicați direct în expediere și reglementare ai clienților noștri pentru a anticipa și soluționa prompt problemele ce pot apărea în timpul transportului. Asigurăm respectarea tuturor cerințelor vamale pentru țările de export și import înainte de preluarea trimiterii, minimizând astfel eventualele întârzieri cauzate de formalitățile vamale.',
  },
  {
    title: 'Marfuri periculoase',
    text: 'Teen Logistics vă poate asista în conformitate cu reglementările din ce în ce mai restrictive în domeniul transportului mărfurilor periculoase, oferind servicii complete de intocmire a documentației, ambalare, etichetare și transport. Beneficiați de curieri atestați ADR și consultanță specializată pentru expedierea legală și eficientă a materialelor periculoase conform reglementărilor ADR/ICAO/IMDG.',
  },
  {
    title: 'Next Flight Out',
    text: 'Teen Logistics oferă o soluție globală de export rapidă și economică, utilizând agent cargo al companiilor aeriene pentru preluarea și livrarea rapidă a coletelor prin autovehicule dedicate. Documentația de transport aerian este gestionată individual pentru fiecare expediere, optimizând timpii și costurile comparativ cu metodele tradiționale de curierat.',
  },
  {
    title: 'Same Day Direct',
    text: 'In interiorul spatiului economic european, oferim servicii Airport-To-Airport cu zboruri directe catre majoritatea capitalelor europene. Destinatarii dvs pot prelua coletele pe care dvs ni le inmanati in aceeasi zi, din aeroportul cel mai apropiat, direct de la agentii de handling ai companiilor aeriene.',
  },
];

const Features = () => (
  <div className="gpt3__features section__padding" id="curierat">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">Transport Sigur și Optimizat pentru Produse Medicale și Biologice Sensibile</h1>
      <p>Experti in coordonarea logistica si transportul produselor biologice, medicale, farmaceutice, la temperaturi controlate, Teen Logistics ofera un raport excelent intre minimizarea riscurilor si optimizarea costurilor dvs., aducandu-va un plus de siguranta in acest domeniu atat de complex reglementat.</p>
      <p>Avand capacitatea de a satisface toate cerintele de expediere, inclusiv prin ambalare activa sau pasiva, Teen Logistics combina experienta, integritatea temperaturii si comunicarea in timp real pt a va oferi siguranta ca medicamentele, vaccinurile, probele de diagnostic sau esantioanele de referinta ajung la destinatie intacte si fara devieri de temperatura in afara limitelor adecvate.</p>
      <p>Scopul nostru este sa eliminam orice umbra de stress din procesul de expediere prin intelegerea produselor si cerintelor clientului, astfel incat, printr-o colaborare flexibila, sa putem personaliza si implementa solutia potrivita.</p>
      <p>Specialistii nostri vin cu decenii de experienta in expedierea si ambalarea articolelor:</p>
      <ul>
        <li>Congelate, inclusiv de criogenizare -20C, -80C, -150C;</li>
        <li>Refrigerate: +2C - +8 C</li>
        <li>Ambientale: +15C - +25C</li>
      </ul>
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
