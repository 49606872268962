import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../logo.svg';
import "../feature/feature.css"
import './navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo gpt3__features-container__feature-titlex">
          <h1>TeenLogistics</h1>
        </div>
        <div className="gpt3__navbar-links_container">
          <p><a href="#servicii">Servicii</a></p>
          <p><a href="#curierat">Curierat</a></p>
          <p><a href="#about">Cine Suntem</a></p>
          <p><a href="#cert">Certificari</a></p>
          <p><a href="#contact">Contact</a></p>
        </div>
      </div>
      <div className="gpt3__navbar-sign">
        <a href="#track"> <button type="button"> Track My Order </button>  </a>
      </div>
      <div className="gpt3__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#272727" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#272727" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
        <div className="gpt3__navbar-menu_container scale-up-center">
          <div className="gpt3__navbar-menu_container-links">
            <p><a href="#servicii">Servicii</a></p>
            <p><a href="#curierat">Curierat</a></p>
            <p><a href="#about">Cine Suntem</a></p>
            <p><a href="#cert">Certificari</a></p>
            <p><a href="#contact">Contact</a></p>
          </div>
          <div className="gpt3__navbar-menu_container-links-sign">
            <button type="button">Track My Order</button>
          </div>
        </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
