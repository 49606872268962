import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";

function Edit() {
	const [data, setData] = useState([{Status: ''}]);
	const [status, setStatus] = useState({
        date: "",
        details: "Picked Up",
        comments: ""
    })
	const [initStatus, setInitStatus] = useState('');
	const { id } = useParams();

	useEffect(() => {
		axios
		.get(`http://localhost:1000/get_awb/${id}`)
		.then((res) => {
			setInitStatus(res.data[0].Status)
			setData(res.data);
		})
		.catch((err) => console.log(err));
	}, [id]);

	const navigate = useNavigate();

	function handleSubmit(e) {
		e.preventDefault();

		var initStatus = JSON.parse(data[0].Status);

		initStatus.push(status);
		let temp = JSON.stringify(initStatus);

        setData([{...data[0], Status: temp}])
	}

	useEffect(() => {
        if (data[0].Status != initStatus)
        {

            axios
			.post(`http://localhost:1000/edit_user/${id}`, data[0])
			.then((res) => {
				navigate("/admin");
				console.log(res);
			})
			.catch((err) => console.log(err));
        }
    }, [data[0].Status])

	return (
		<div className="container vw-100 vh-100">
			<h1 className="my-3 ms-4">Edit - {id}</h1>
			<Link to="/admin" className="btn btn-success my-3 ms-4">
				Back
			</Link>
			<div className="my-3 ms-4">
				{data.map((del) => {
					return (
					<form onSubmit={handleSubmit}>
						<div className="form-group my-3">
							<label htmlFor="awb" className='me-4'>AWB</label>
							<input
								value={del.AWB}
								type="text"
								name="awb"
								required
								onChange={(e) =>
								setData([{ ...data[0], AWB: e.target.value }])
								}
							/>
						</div>
						<div className="form-group my-3">
							<label htmlFor="route" className='me-4'>Route</label>
							<input
								value={del.Route}
								type="text"
								name="route"
								required
								onChange={(e) =>
								setData([{ ...data[0], Route: e.target.value }])
								}
							/>
						</div>
						<div className="form-group my-3">
							<label htmlFor="PkgDetails" className='me-4'>PkgDetails</label>
							<input
								value={del.PkgDetails}
								type="text"
								name="PkgDetails"
								required
								onChange={(e) =>
								setData([{ ...data[0], PkgDetails: e.target.value }])
								}
							/>
						</div>

						<h3>Status</h3>

						<div className='form-group my-3'>
							<label htmlFor='date' className='me-4'>Date</label>
							<input type='datetime-local' name='date' required onChange={(e)=> setStatus({...status, date: e.target.value.toString()})} />
						</div>
						<div className='form-group my-3'>
							<label htmlFor='status' className='me-4'>Status</label>
							<select 
								value={status.details} 
								onChange={(e)=> setStatus({...status, details: e.target.value})} 
							>
								<option value="Picked Up">Picked Up</option>
								<option value="Pending Clearance">Pending Clearance</option>
								<option value="Export Gateway">Export Gateway</option>
								<option value="In Transit">In Transit</option>
								<option value="Arrived At Destination Port">Arrived At Destination Port</option>
								<option value="Customs Released">Customs Released</option>
								<option value="Delivered">Delivered</option>
							</select>
						</div>
						<div className='form-group my-3'>
							<label htmlFor='comments' className='me-4'>Comments</label>
							<input type='text' name='text' required onChange={(e)=> setStatus({...status, comments: e.target.value})} />
						</div>

						<div className="form-group my-3">
						<button type="submit" className="btn btn-success">
							Save
						</button>
						</div>
					</form>
					);
				})}
			</div>
		</div>
	);
	
}

export default Edit;
