import React from 'react';
import people from '../../assets/people.png';
import dna from '../../assets/dna.png';
import './header.css';

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">Servicii premium de curierat rapid</h1>
      <p>Solutii dedicate domeniului bio - medical, in conditii critice de timp si temperatura. Casa de expeditii aeriene, maritime si multimodale.</p>
    </div>

    <div className="gpt3__header-image">
      <img src={dna} />
    </div>
  </div>
);

export default Header;
