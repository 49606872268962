import React, { useState } from 'react';
import './cta.css';
import "../../containers/header/header.css"
import axios from 'axios';
import "bootstrap/dist/css/bootstrap.css";

const CTA = () => {
	const [awb, setAwb] = useState("");
	const [data, setData] = useState([]);
	const [shouldDisplay, setShouldDisplay] = useState(false)

	function searchAwb(cod) {
		setShouldDisplay(true);

		axios
		.get(`http://localhost:1000/get_awb/${cod}`)
		.then((res) => {
			console.log(res.data)
			setData(res.data);
		})
		.catch((err) => console.log(err));
	}

	const months = ["ianuarie", "februarie", "martie", "aprilie", "mai", "iunie", "iulie", "august", "septembrie", "octombrie", "noiembrie", "decembrie"]

	return ( 
		<div className='gpt3__cta-container' id="track">
			<div className="gpt3__cta">
			<div className="gpt3__cta-content">
				<p>OBSERVATI TRASEUL COLETULUI DVS.</p>
				<h3>Track My Order</h3>
			</div>
			
			{/* <div className="gpt3__cta-btn">
				<button type="button">Get Started</button>
			</div> */}
		</div>
		<div className="gpt3__header-content__input">
			<input value={awb} required type="text" placeholder="AWB-ul coletului dvs." onChange={(e) => setAwb(e.target.value)}/>
			{/* <button type="button" onClick={() => searchAwb(awb)}>Cautare</button> */}
			<button type="button" onClick={() => window.open(`http://www.teentrans.com/awb/index.php?awb=${awb.replace(" ", "+")}`, '_blank').focus()}>Cautare</button>
		</div>
			{/* <div className="gpt3__cta-table">
				{
					data.length != 0 ? data.map((del)=>{
						return (<ul key={Math.random()} className="list-group">
							<li className="list-group-item">
								<b>AWB: </b>
								{del.AWB}
							</li>
							<li className="list-group-item">
								<b>Route: </b>
								{del.Route}
							</li>
							<li className="list-group-item">
								<b>PkgDetails: </b>
								{del.PkgDetails}
							</li>
							<li className="list-group-item">
								<b>Status: </b>
								<div className='table-responsive'>
									<table className="table">
										<thead>
											<tr>
												<th>Date</th>
												<th>Status</th>
												<th>Details</th>
											</tr>
										</thead>
										<tbody>
										{
											JSON.parse(del.Status).map((change) => {
												return (
													<tr key={Math.random()}>
														<td>{new Date(change.date).getDate() + "-" + months[new Date(change.date).getMonth()] + "-" + new Date(change.date).getFullYear() + " | " + new Date(change.date).getHours() + ":" + ('0' + new Date(change.date).getMinutes()).slice(-2)}</td>
														<td>{change.details}</td>
														<td>{change.comments}</td>
													</tr>
												)
											})
										}
										</tbody>
									</table>
								</div>
							</li>
						</ul>)
					}) : null
				} 
				<div className='gpt3__cta-content'>
					{
						shouldDisplay ? (data.length != 0 ? null : <p>AWB-ul introdus nu a fost gasit!</p>) : null
					}
				</div>
			</div> */}
		</div>
	);
};

export default CTA;
