import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import styles from "./styles.module.css";

const AdminLogin = () => {
    const [data, setData] = useState({ username: "", password: "" });
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
			const url = "http://localhost:1000/auth";
			const { data: res } = await axios.post(url, data);
			localStorage.setItem("token", res.data);
			window.location = "/admin";
		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
			}
		}
    };

    return (
        <div className="container mt-5">
            <h2>Admin Login</h2>
            <form onSubmit={handleLogin}>
                <div className="form-group">
                <label>Username</label>
                <input
                    type="text"
                    className="form-control"
                    value={data.username}
                    onChange={(e) => setData({...data, username: e.target.value})}
                    required
                />
                </div>
                <div className="form-group">
                <label>Password</label>
                <input
                    type="password"
                    className="form-control"
                    value={data.password}
                    onChange={(e) => setData({...data, password: e.target.value})}
                    required
                />
                </div>
                {error && <div className={styles.error_msg}>{error}</div>}
                <button type="submit" className="btn btn-primary mt-3">Login</button>
            </form>
        </div>
    );
};

export default AdminLogin;
