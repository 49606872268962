import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {Link, useNavigate} from 'react-router-dom'

function Create() {
    const [status, setStatus] = useState({
        date: "",
        details: "Picked Up",
        comments: ""
    })

    const [values, setValues] = useState({
        awb: '',
        route: '',
        pkgDetails: '',
        statusArr: ''
    })

    const navigate = useNavigate()

    useEffect(() => {
        if (values.statusArr != '')
        {
            axios.post('http://localhost:1000/add_awb', values)
            .then((res)=>{
                
                navigate('/admin')
                console.log(res)
            })
            .catch((err)=>console.log(err))
        }
    }, [values.statusArr])
    

    function handleSubmit(e){
        e.preventDefault()

        let temp = JSON.stringify([{...status}])

        setValues({...values, statusArr: temp})
    }

    return (
        <div className='container vh-100 vw-100'>
            <div className='row'>
                <h3 className="my-3 ms-4">Create</h3>
                <div className='ms-4'>
                    <Link to='/admin' className='btn btn-success'>Home</Link>
                </div>
                <form onSubmit={handleSubmit} className="my-3 ms-4">
                    <div className='form-group my-3'>
                        <label htmlFor='awb' className='me-3'>AWB</label>
                        <input type='text' name='awb' required onChange={(e)=> setValues({...values, awb: e.target.value})} />
                    </div>
                    <div className='form-group my-3'>
                        <label htmlFor='route' className='me-3'>Route</label>
                        <input type='text' name='route' required onChange={(e)=> setValues({...values, route: e.target.value})} />
                    </div>
                    <div className='form-group my-3'>
                        <label htmlFor='pkgDetails' className='me-3'>Pkg Details</label>
                        <input type='text' name='pkgDetails' required onChange={(e)=> setValues({...values, pkgDetails: e.target.value})} />
                    </div>

                    <h3>Status</h3>

                    <div className='form-group my-3'>
                        <label htmlFor='date' className='me-3'>Date</label>
                        <input type='datetime-local' name='date' required onChange={(e)=> setStatus({...status, date: e.target.value.toString()})} />
                    </div>
                    <div className='form-group my-3'>
                        <label htmlFor='status' className='me-3'>Status</label>
                        <select 
                            value={status.details} 
                            onChange={(e)=> setStatus({...status, details: e.target.value})} 
                        >
                            <option value="Picked Up">Picked Up</option>
                            <option value="Pending Clearance">Pending Clearance</option>
                            <option value="Export Gateway">Export Gateway</option>
                            <option value="In Transit">In Transit</option>
                            <option value="Arrived At Destination Port">Arrived At Destination Port</option>
                            <option value="Customs Released">Customs Released</option>
                            <option value="Delivered">Delivered</option>
                        </select>
                    </div>
                    <div className='form-group my-3'>
                        <label htmlFor='comments' className='me-3'>Comments</label>
                        <input type='text' name='text' required onChange={(e)=> setStatus({...status, comments: e.target.value})} />
                    </div>

                    <div className='form-group my-3'>
                        <button type='submit' className='btn btn-success'>Save</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Create