import React from 'react';
import Article from '../../components/article/Article';
import { blog01, blog02, blog03, blog04, blog05 } from './imports';
import './blog.css';

const Blog = () => (
  <div className="gpt3__blog section__padding" id="servicii">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text">Servicii</h1>
    </div>
    <div className="gpt3__blog-container">
      <div className="gpt3__blog-container_groupB">
        <Article imgUrl={blog01} url="#contact" btnText="Contactati-ne" date="TEEN Logistics are sediul in aeroportul Otopeni, fiind Agent cargo pt majoritatea companiilor aeriene operationale in RomaniaServicii optimizate pentru colete de orice greutate si dimensiune." text="Expeditii aeriene" />
        <Article imgUrl={blog02} url="#curierat" btnText="Aflati mai multe" date="Teen Logistics este axata pe servicii de curierat dedicate domeniului bio-medical. Oferim ambalare la temperaturi controlate, asigurand ambalajele specifice tipurilor de produse expediate. Pentru mai multe informatii, contactati-ne." text="Curierat rapid" />
        <Article imgUrl={blog03} url="#contact" btnText="Contactati-ne" date="Pt partide de marfa care nu necesita o durata critica de livrare, sistemul de transport maritim poate fi solutia optima de mentinere a unor costuri minime. Teen Logistics ofera expeditii maritime in sistem containerizat, de grupaj si multimodal." text="Expeditii maritime" />
      </div>
    </div>
  </div>
);

export default Blog;
