import React from 'react';
import './article.css';
import "../../components/navbar/navbar.css"

const Article = ({ imgUrl, date, text, btnText, url }) => (
  <div className="gpt3__blog-container_article">
    <div className="gpt3__blog-container_article-image">
      <img src={imgUrl} alt="blog_image" />
    </div>
    <div className="gpt3__blog-container_article-content">
      <div>
        <h3>{text}</h3>
        <p>{date}</p>
      </div>
      <div className='gpt3__navbar-signn'>
        <a href={url}> <button type="button">{btnText}</button>  </a>
      </div>
    </div>
  </div>
);

export default Article;
